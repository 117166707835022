body {
	height: 100vh;
	padding: 0;

}
.main-content {
	padding-top: 60px;
}
.main-header {
	margin-top: 0px;
	z-index: 5;
}
.sub-header {
	margin-top: 0px;
	z-index: 4;
}
.sub-main-content {
	margin-top: 120px;
	z-index: 3;
}
.rdt_TableHeadRow {
	font-size: medium;
	font-weight: 800;
	/* background-color: rgba(0, 0, 0, 0.122) !important; */
}
.rdt_TableHeadD .rdt_TableHeadRow {
	background-color: #fff !important;
}